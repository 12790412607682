import MKTypography from "components/MKTypography";

import logoVileala from "assets/images/vileala-logo-full-dark.png";
import logoLightVileala from "assets/images/vileala-logo-full-light.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import InstagramIcon from "@mui/icons-material/Instagram"

const date = new Date().getFullYear();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  brand: {
    name: "Vileala",
    image: logoVileala,
    imageLight: logoLightVileala,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/vileala/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/vilealagmbh",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/vilealagmbh",
    },
  ],

  menus: [
      /*
    {
      name: "informationen",
      items: [
        { name: "über uns", href: "https://www.creative-tim.com/presentation" },
      ],
    },*/
    {
      name: "rechtliches",
      items: [
        {
          name: "impressum",
          route: "/impressum",
        },
        {
          name: "datenschutz",
          route: "/datenschutz",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} -{" "}
      <MKTypography
        component="a"
        href="https://vileala.ch"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Vileala GmbH
      </MKTypography>
    </MKTypography>
  ),
};

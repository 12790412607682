import Grid from "@mui/material/Grid2";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import {List, ListItem} from "@mui/material";

function Eryngii({title, image, ingredients, preparation}) {

    return (
        <MKBox component="section" position="relative" py={6} mt={2}>
            <Container sx={{ maxWidth: "100% !important" }}>
                <Grid container xs={10} sx={{ mx: "auto" }}>
                    <MKBox bgColor="light" shadow="lg" borderRadius="lg" width="100%" py={6}>
                        <Grid container>
                            <Grid size={{xs: 12, md: 6, xl:4}} sx={{ position: "relative", px: 6 }}>
                                <MKBox
                                    component="img"
                                    src='/img/eryngii.webp'
                                    alt="Pleurotus eryngii"
                                    borderRadius="md"
                                    maxWidth="500px"
                                    width="100%"
                                    position="relative"
                                    mt={-12}
                                />
                            </Grid>
                            <Grid
                                size={{xs: 12, md: 5, xl: 8}}
                                sx={{
                                    position: "relative",
                                    px: { xs: 6, md: 0 },
                                    mt: { xs: 3, md: 0 },
                                    my: { xs: 0, md: 0 },
                                    color: ({ palette: { white } }) => white.main,
                                }}
                            >
                                <MKTypography
                                    variant="h3"
                                    color="dark"
                                    mb={4}
                                >
                                    Kräuterseitling (Pleurotus eryngii)
                                </MKTypography>

                                <MKTypography variant="h5" color="dark">Allgemeines</MKTypography>
                                <MKTypography variant="body2" mb={2}>
                                    Der Kräuterseitling gehört zur Familie der Seitlinge und ist ein besonderer Speisepilz, der sich durch seinen festen Biss und sein nussiges Aroma auszeichnet. Er ist eine edle Alternative zum klassischen Champignon und bietet vielseitige Einsatzmöglichkeiten in der Küche.
                                </MKTypography>
                                <MKTypography variant="h5" color="dark">Merkmale</MKTypography>
                                <MKTypography component="div" variant="body2" mb={2}>
                                    <List sx={{ listStyleType: 'disc', pl: 2 }}>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <b>Hut:</b> 3–10 cm gross, glatt, hellbraun bis ockerfarben
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <b>Lamellen:</b> Weiss bis cremefarben, leicht herablaufend
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <b>Stiel:</b> Dick, fest, weiss, vollfleischig
                                        </ListItem>
                                        <ListItem sx={{ display: 'list-item' }}>
                                            <b>Geschmack:</b> Nussig, mild, mit einer angenehmen, leicht süsslichen Note
                                        </ListItem>
                                    </List>
                                </MKTypography>

                                <MKTypography variant="h5" color="dark">Besonderheiten</MKTypography>
                                <MKTypography variant="body2" color="dark" mb={2}>
                                    Der Kräuterseitling bleibt auch beim Braten bissfest und entwickelt dabei eine zarte, fleischähnliche Textur.
                                    Er ist reich an Eiweiss, Ballaststoffen und enthält wertvolle Mineralstoffe wie Kalium, Phosphor und Magnesium.
                                    Durch seinen geringen Wassergehalt schrumpft er beim Garen kaum.
                                </MKTypography>

                                <MKTypography variant="h5" color="dark">Verwendung in der Küche</MKTypography>
                                <MKTypography variant="body2" color="dark" mb={2}>
                                    Ideal zum Braten, Grillen oder Schmoren
                                    Hervorragend als Fleischersatz in vegetarischen und veganen Gerichten
                                    Passt perfekt zu Pasta, Risotto, Salaten oder einfach pur mit etwas Knoblauch und Butter angebraten
                                </MKTypography>

                                <MKTypography variant="h5" color="dark">Lagerung & Haltbarkeit</MKTypography>
                                <MKTypography variant="body2" color="dark" mb={2}>
                                    Am besten im Kühlschrank bei 2–7 °C lagern.
                                    Hält sich frisch bis zu einer Woche, idealerweise in einer Papiertüte oder einem luftdurchlässigen Behälter
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
            </Container>
        </MKBox>
    )
}

export default Eryngii
import { useEffect, useRef } from "react";

import Rellax from "rellax";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Navbar from "components/Navbar";
import Footer from "components/Footer";


import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/bg-about.webp";
import LukasWeber from "./sections/LukasWeber";
import BrandCard from "./components/BrandCard";
import logoVileala from "assets/images/vileala-logo-full-dark.png";

function About() {
    const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);


  return (
    <>
      <Navbar
          light
          brand={footerRoutes.brand}
          routes={routes}
          transparent
      />
      <MKBox
        ref={headerRef}
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            size={{xs: 12, lg: 8}}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
              <BrandCard image={logoVileala}
                         name={<MKTypography fontWeight="bold">Vileala <span style={{fontStyle: 'italic'}}>[ˈʋile̞ɑlɑ] („Wille-a-la“)</span> </MKTypography>}
                         review={<MKTypography>Abgeleitet vom finnischen Wort <strong>„viljelyala“ </strong>
                             <span style={{fontStyle: 'italic'}}>[ˈʋilje̞lyˌɑ̝lɑ̝]</span>, was soviel wie „Anbaufläche“ bedeutet.</MKTypography>} />
          </Grid>
        </Container>
      </MKBox>

        <Card
            sx={{
                p: 0,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                overflow: "hidden",
                minHeight: "20vh"
            }}
        >
            <Grid container>
                <Grid size={12}>
                    <LukasWeber />
                </Grid>
            </Grid>
        </Card>

      <MKBox pt={3} px={1} mt={3}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default About;

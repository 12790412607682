import Grid from "@mui/material/Grid2";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";

function LukasWeber() {
    return (
        <MKBox
            component="section"
            display="grid"
            position="relative"
            minHeight="90vh"
            borderRadius="xl"
            mr={{ xs: 0, lg: -2 }}
            mb={{ xs: 0, lg: -2 }}
            sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
        >
            <MKBox
                component="img"
                alt="lukas weber"
                src="/img/lukas.webp"
                width={{ xs: "100%", lg: "50%" }}
                height={{ xs: "100%", lg: "100%" }}
                position={{ xs: "initial", lg: "absolute" }}
                right={0}
                bottom={{ xs: "-25%", lg: "unset" }}
                top={{ xs: 0, lg: "unset" }}
                sx={{
                    objectFit: "cover",
                    borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
                }}
            />
            <Container>
                <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: -3, lg: 12 } }}>
                    <Grid size={{xs: 12, lg: 7}} justifyContent="center" flexDirection="column">
                        <MKBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            shadow="lg"
                            borderRadius="xl"
                            sx={{
                                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                                    rgba(white.main, 0.8),
                                backdropFilter: "saturate(200%) blur(30px)",
                                px: { xs: 3, sm: 6 },
                                py: { xs: 3, sm: 8 },
                                mb: { xs: 0, lg: 8 },
                                mt: { xs: 0, lg: -6 },
                            }}
                        >
                            <MKTypography variant="h3" textAlign="center" mb={2}>
                                Lukas Weber
                            </MKTypography>
                            <MKBox py={2} px={{ xs: 3, md: 12, lg: 8, xl: 8 }}>
                                <MKTypography component="div" variant="body1" color="text" pb={1}>
                                    Ich bin Lukas Weber, aufgewachsen im Thurgau am Bodensee. Schon
                                    während meiner Lehre bei einer Bank, entdeckte ich meine Leidenschaft für die Informatik. Der Zufall
                                    wollte es so, dass ich mich im Anschluss an meine Lehre intern in
                                    diese Richtung aus- und weiterbilden konnte. Ich blieb dem Finanzsektor etwa 15 Jahre treu und durfte viele
                                    spannende und herausfordernde Projekte begleiten auf welche ich mit Stolz und Freude zurückblicken kann.
                                </MKTypography>

                                <MKTypography component="div" variant="body1" color="text" pb={1}>
                                    Nach einer kurzen Phase der Selbstständigkeit, entschied ich mich wieder
                                    für eine Festanstellung im Bereich der IT-Security. Diese Tätigkeit, in
                                    einem deutlich kleineren und jüngeren Unternehmen, bot mir die Möglichkeit, aus dem Fundus meiner
                                    Erfahrungen zu schöpfen und in einem sehr dynamischen Umfeld, viele neue
                                    Dinge zu schaffen. Jedoch musste ich im Laufe der Zeit feststellen, dass mir dieser Beruf, die Aufgaben, die
                                    langen und anspruchsvollen Tage vor dem Bildschirm, zunehmend weniger Erfüllung bringen.

                                    Unsere Welt verändert sich rasant und das Thema Nachhaltigkeit wird immer drängender und wichtiger. Ich
                                    will einen Beitrag leisten zur positiven Veränderung unserer Umwelt und Lebensweise. Glücklicherweise hat
                                    mich mein damaliger Arbeitgeber bei meiner Neuorientierung ausserordentlich gut unterstützt. So hatte
                                    ich die Möglichkeit, mich intensiv mit Alternativen auseinander zu setzten.
                                    Ich habe mich mit Fisch-, Insekten und Algenzucht beschäftigt, Praktika absolviert und mich mit allem
                                    intensiv auseinandergesetzt. Alles war spannend, aber erst nach meinem Praktikum bei einer Edelpilzzucht war
                                    klar, was ich künftig machen wollte.
                                </MKTypography>
                                <MKTypography component="div" variant="body1" color="text" pb={1}>
                                    Die Welt der Pilze ist faszinierend!

                                    Ich las mich in die Fachliteratur ein und entwickelte meinen Plan für eine
                                    eigene Pilzzucht. Diese habe ich nun in Wagerswil TG erbaut und eingerichtet.
                                </MKTypography>
                                <MKTypography component="div" variant="body1" color="text" pb={1}>
                                    Ich bin interessiert, motiviert und vor allem eines – ich bin bereit!
                                </MKTypography>
                            </MKBox>
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    )
}

export default LukasWeber
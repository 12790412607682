import Card from "@mui/material/Card";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import Grid from "@mui/material/Grid2";
import MKAvatar from "../../../components/MKAvatar";


function BrandCard({ image, name, review }) {
    return (
        <Grid container spacing={3} sx={{ mt: 10, mb: 6 }} justifyContent="center">
            <Grid xs={12} md={4}>
                <Card variant="outlined" sx={{backgroundColor: '#ffffffa6'}}>
                    <MKBox display="grid" justifyContent="center" position="relative" mt={-7} >
                        <MKAvatar
                            src={image}
                            alt="Vileala"
                            size="xxl"
                            shadow="lg"
                            bgColor="white"
                            sx={{ position: "relative", zIndex: 2, px: 1 }}
                        />
                    </MKBox>
                    <MKBox pt={3} px={3} textAlign="center">
                        <MKTypography component="div" variant="h4" fontWeight="bold">
                            {name}
                        </MKTypography>
                        <MKTypography component="div" variant="body2" color="text" mt={1} mb={2}>
                            {review}
                        </MKTypography>
                    </MKBox>
                </Card>
            </Grid>
        </Grid>
    );
}

export default BrandCard;
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import About from "./pages/About";
import Recipes from "./pages/Recipes";
import {Mushroom} from "./components/Icons";
//import {Agriculture} from "@mui/icons-material";
import Species from "./pages/Species";

const routes = [
    /*{
        key: "cultivation",
        name: "zucht",
        icon: <Agriculture />,
        route: "/cultivation",
        component: <Recipes />
    },*/
    {
        key: "species",
        name: "arten",
        icon: <Mushroom />,
        route: "/species",
        component: <Species />
    },
    {
        key: "recipes",
        name: "rezepte",
        icon: <ReceiptLongIcon />,
        route: "/recipes",
        component: <Recipes />
    },
    {
        key: "about",
        name: "über uns",
        icon: <PeopleOutlineIcon />,
        route: "/about",
        component: <About />
    }
  /*{
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "paul",
            route: "/paul",
            //component: <Welcome />,
          },
        ],
      },
      {
        name: "company",
        collapse: [
          {
            name: "about us",
            route: "/pages/company/about-us",
            component: <AboutUs />,
          },
          {
            name: "pricing",
            route: "/pages/company/pricing",
            component: <Pricing />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "help center",
            route: "/pages/support/help-center",
            component: <HelpCenter />,
          },
          {
            name: "contact us",
            route: "/pages/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "faq",
            route: "/pages/support/faq",
            component: <Faq />,
          },
          {
            name: "privacy",
            route: "/pages/support/privacy",
            component: <Privacy />,
          },
        ],
      },
      {
        name: "apps",
        collapse: [
          {
            name: "desktop app",
            route: "/pages/apps/desktop-app",
            component: <DesktopApp />,
          },
        ],
      },
      {
        name: "blogs",
        collapse: [
          {
            name: "single article",
            route: "/pages/blogs/single-article",
            component: <SingleArticle />,
          },
          {
            name: "author",
            route: "/pages/blogs/author",
            component: <Author />,
          },
        ],
      },
      {
        name: "extra",
        collapse: [
          {
            name: "virtual reality",
            route: "/pages/extra/virtual-reality",
            component: <VirtualRealityPage />,
          },
        ],
      },


    ],
  }, */
  /*
  {
    name: "sections",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "page sections",
        description: "See all 55 sections",
        dropdown: true,
        collapse: [
          {
            name: "page headers",
            route: "/sections/page-sections/page-headers",
            component: <PageHeaders />,
          },
          {
            name: "features",
            route: "/sections/page-sections/features",
            component: <Features />,
          },
          {
            name: "pricing",
            route: "/sections/page-sections/pricing",
            component: <PricingSection />,
          },
          {
            name: "faq",
            route: "/sections/page-sections/faq",
            component: <FaqSection />,
          },
          {
            name: "blog posts",
            route: "/sections/page-sections/blog-posts",
            component: <BlogPosts />,
          },
          {
            name: "testimonials",
            route: "/sections/page-sections/testimonials",
            component: <Testimonials />,
          },
          {
            name: "teams",
            route: "/sections/page-sections/teams",
            component: <Teams />,
          },
          {
            name: "stats",
            route: "/sections/page-sections/stats",
            component: <Stats />,
          },
          {
            name: "call to actions",
            route: "/sections/page-sections/cta",
            component: <Cta />,
          },
          {
            name: "applications",
            route: "/sections/page-sections/applications",
            component: <Applications />,
          },
          {
            name: "logo areas",
            route: "/sections/page-sections/logo-areas",
            component: <LogoAreas />,
          },
          {
            name: "footers",
            route: "/sections/page-sections/footers",
            component: <Footers />,
          },
          {
            name: "general cards",
            route: "/sections/page-sections/general-cards",
            component: <GeneralCards />,
          },
          {
            name: "content sections",
            route: "/sections/page-sections/content-sections",
            component: <ContentSections />,
          },
        ],
      },
      {
        name: "navigation",
        description: "See all 3 navigations",
        dropdown: true,
        collapse: [
          {
            name: "navbars",
            route: "/sections/navigation/navbars",
            component: <Navbars />,
          },
          {
            name: "nav tabs",
            route: "/sections/navigation/nav-tabs",
            component: <NavTabs />,
          },
          {
            name: "pagination",
            route: "/sections/navigation/pagination",
            component: <Pagination />,
          },
        ],
      },
      {
        name: "input areas",
        description: "See all 8 input areas",
        dropdown: true,
        collapse: [
          {
            name: "newsletters",
            route: "/sections/input-areas/newsletters",
            component: <Newsletters />,
          },
          {
            name: "contact sections",
            route: "/sections/input-areas/contact-sections",
            component: <ContactSections />,
          },
          {
            name: "inputs",
            route: "/sections/input-areas/inputs",
            component: <Inputs />,
          },
          {
            name: "forms",
            route: "/sections/input-areas/forms",
            component: <Forms />,
          },
        ],
      },
      {
        name: "attention catchers",
        description: "See all 5 examples",
        dropdown: true,
        collapse: [
          {
            name: "alerts",
            route: "/sections/attention-catchers/alerts",
            component: <Alerts />,
          },
          {
            name: "notifications",
            route: "/sections/attention-catchers/notifications",
            component: <Notifications />,
          },
          {
            name: "modals",
            route: "/sections/attention-catchers/modals",
            component: <Modals />,
          },
          {
            name: "tooltips & popovers",
            route: "/sections/attention-catchers/tooltips-popovers",
            component: <TooltipsPopovers />,
          },
        ],
      },
      {
        name: "elements",
        description: "See all 32 examples",
        dropdown: true,
        collapse: [
          {
            name: "avatars",
            route: "/sections/elements/avatars",
            component: <Avatars />,
          },
          {
            name: "badges",
            route: "/sections/elements/badges",
            component: <Badges />,
          },
          {
            name: "breadcrumbs",
            route: "/sections/elements/breadcrumbs",
            component: <BreadcrumbsEl />,
          },
          {
            name: "buttons",
            route: "/sections/elements/buttons",
            component: <Buttons />,
          },
          {
            name: "dropdowns",
            route: "/sections/elements/dropdowns",
            component: <Dropdowns />,
          },
          {
            name: "progress bars",
            route: "/sections/elements/progress-bars",
            component: <ProgressBars />,
          },
          {
            name: "social buttons",
            route: "/sections/elements/social-buttons",
            component: <SocialButtons />,
          },
          {
            name: "tables",
            route: "/sections/elements/tables",
            component: <Tables />,
          },
          {
            name: "toggles",
            route: "/sections/elements/toggles",
            component: <Toggles />,
          },
          {
            name: "typography",
            route: "/sections/elements/typography",
            component: <Typography />,
          },
        ],
      },
    ],
  },
    */
];

export default routes;

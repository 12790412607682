import Grid from "@mui/material/Grid2";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import {AppBar, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs} from "@mui/material";
import {useState} from "react";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <MKBox sx={{ p: 3 }}>
                    {children}
                </MKBox>
            )}
        </div>
    );
}

function Recipe({title, image, ingredients, preparation}) {
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };


    return (
        <MKBox component="section" position="relative" py={6} mt={2}>
            <Container sx={{ maxWidth: "100% !important" }}>
                <Grid container xs={10} sx={{ mx: "auto" }}>
                    <MKBox bgColor="light" shadow="lg" borderRadius="lg" width="100%" py={6}>
                        <Grid container>
                            <Grid size={{xs: 12, md: 6, xl:4}} sx={{ position: "relative", px: 6 }}>
                                <MKBox
                                    component="img"
                                    src={image}
                                    alt="recipe"
                                    borderRadius="md"
                                    maxWidth="500px"
                                    width="100%"
                                    position="relative"
                                    mt={-12}
                                />
                            </Grid>
                            <Grid
                                size={{xs: 12, md: 5, xl: 8}}
                                sx={{
                                    position: "relative",
                                    px: { xs: 6, md: 0 },
                                    mt: { xs: 3, md: 0 },
                                    my: { xs: 0, md: 0 },
                                    color: ({ palette: { white } }) => white.main,
                                }}
                            >
                                <MKTypography
                                    variant="h4"
                                    color="dark"
                                    mb={4}
                                >
                                    {title}
                                </MKTypography>
                                <AppBar position="static" sx={{px: {xs: 1, lg: 8}}}>
                                    <Tabs value={tab} onChange={handleTabChange} aria-label="disabled tabs example">
                                        <Tab label="Zutaten" />
                                        <Tab label="Zubereitung" />
                                    </Tabs>
                                </AppBar>

                                <TabPanel value={tab} index={0}>
                                    <Table size="small" sx={{borderCollapse: 'collapse'}}>
                                        <TableHead sx={{display: "table-header-group",
                                            '& th': {paddingTop: 0, paddingBottom: 0}}}>
                                            <TableRow >
                                                <TableCell sx={{borderBottomWidth: 0, textAlign: 'right', width: 'fit-content'}}/>
                                                <TableCell sx={{borderBottomWidth: 0}}/>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={{
                                            "& tr>td": {
                                                paddingTop: 0.5,
                                                paddingBottom: 0.5
                                            }
                                        }}>
                                            {ingredients.map((item, idx) => {
                                                if(item) {
                                                    return (
                                                        <TableRow key={idx}>
                                                            {item.hasOwnProperty('heading') ?
                                                                <TableCell colSpan={2}>
                                                                    <MKTypography color="dark" variant="body2" fontWeight="bold">
                                                                        {item.heading}
                                                                    </MKTypography>
                                                                </TableCell>
                                                                :
                                                                <>
                                                                    <TableCell sx={{textAlign: 'right', width: 'fit-content'}}>
                                                                        <MKTypography variant="body2" fontWeight="bold" color="dark">
                                                                            {item.amount}
                                                                        </MKTypography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MKTypography variant="body2" color="dark">
                                                                            {item.ingredient}
                                                                        </MKTypography>
                                                                    </TableCell>
                                                                </>
                                                            }
                                                        </TableRow>)
                                                } else {
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell colSpan={2} sx={{borderBottomWidth: 0}} />
                                                        </TableRow>
                                                    )
                                                }


                                            })}
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <Grid container spacing={2}>

                                        {preparation.map((item, idx) => {
                                            return (<Grid size={12} key={'prep-' + idx}>
                                                <MKTypography variant="body2" color="dark" sx={{textAlign: "center"}}>
                                                    {item}
                                                </MKTypography>
                                                {idx < preparation.length - 1 &&
                                                    <MKTypography variant="body2" color="text" sx={{
                                                        textAlign: 'center',
                                                        lineHeight: 0,
                                                        marginBottom: 1,
                                                        marginTop: 0.5
                                                    }}>
                                                        ___
                                                    </MKTypography>
                                                }
                                            </Grid>)
                                        })}
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
            </Container>
        </MKBox>
    )
}

export default Recipe
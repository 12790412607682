import { useEffect, useRef } from "react";

import Rellax from "rellax";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Navbar from "components/Navbar";
import Footer from "components/Footer";


import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/bg-about.webp";
import Recipe from "./sections/Recipe";

function Recipes() {
    const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);


  return (
    <>
      <Navbar
          light
          brand={footerRoutes.brand}
          routes={routes}
        /*action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "default",
        }}*/
          transparent
      />
      <MKBox
        ref={headerRef}
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            size={{xs: 12, lg: 8}}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
          <MKTypography
                  variant="h2"
                  color="white"
                  mb={2}
                  sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                      },
                  })}
              >
              Rezepte
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
        <Card
            sx={{
                p: 0,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                background: 'linear-gradient(195deg, #11a37c21, #1e7f6421)',
                backdropFilter: "saturate(100%) blur(10px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                overflow: "hidden",
                minHeight: "20vh"
            }}
        >
            <Recipe title="Pilz-Burger" image="/img/pilzburger.webp" ingredients = {[
                {heading: "Für die Sauce"},
                {amount: "½", ingredient: "Zitrone"},
                {amount: "60 g", ingredient: "Cashewmus, Plus 6 EL Wasser"},
                {amount: "1 - 2 EL", ingredient: "Hefeflocken"},
                {amount: "1 TL", ingredient: "Paprikapulver (edelsüss)"},
                {amount: "1 TL", ingredient: "Knoblauchpulver"},
                {amount: "", ingredient: "Salz"},
                null,
                {heading: "Für die Pattis"},
                {amount: "450 g", ingredient: "Kräuterseitlinge"},
                {amount: "2", ingredient: "Zwiebeln (rot)"},
                {amount: "1", ingredient: "Knoblauchzehe"},
                {amount: "2 EL", ingredient: "Olivenöl"},
                {amount: "40 g", ingredient: "Flohsamenschalen (ersatzweise Kichererbsenmehl), Plus 100 ml Wasser"},
                {amount: "15 g", ingredient: "Petersilie"},
                {amount: "100 g", ingredient: "Haferflocken (zart)"},
                {amount: "2 TL", ingredient: "Paprikapulver (edelsüss)"},
                {amount: "3 - 4 EL", ingredient: "Senf (mittelscharf)"},
                {amount: "½ - 1 TL", ingredient: "Salz"},
                {amount: "½ - 1 TL", ingredient: "Pfeffer"},
                {amount: "4 - 6 EL", ingredient: "Bratöl"},
                null,
                {heading: "Zum servieren"},
                {amount: "1", ingredient: "Zwiebeln (rot)"},
                {amount: "1", ingredient: "Tomate"},
                {amount: "1 Handvoll", ingredient: "Nüsslisalat"},
                {amount: "4", ingredient: "Bürgerbrötchen"},
            ]} preparation={[
                <>
                    <strong>Für die Sauce: </strong>Die halbe Zitrone auspressen.
                    Cashewmus mit 6 EL Wasser und 1 EL Zitronensaft verrühren.
                    Hefeflocken, Paprikapulver, Knoblauchpulver und Salz dazugeben und alles gründlich verrühren.
                    Die Sauce bei Bedarf mit etwas Wasser verdünnen und nach Belieben nachwürzen.
                </>,
                <>
                    <strong>Für die Burger-Patties: </strong>Kräuterseitlinge putzen und in feine Würfel schneiden.
                    Zwiebeln und Knoblauch abziehen und ebenfalls in kleine Würfel schneiden.
                    Olivenöl in einer Pfanne erhitzen. Pilze und Zwiebeln 5 Minuten bei mittlerer bis hoher
                    Hitze anbraten, bis sie etwas Farbe bekommen.
                    <i> Am Anfang brauchen Champignons hohe Hitze, damit sie nicht so viel Saft ziehen. </i>
                    Knoblauch für eine Minute dazugeben und mitbraten.
                    Alles mit einer Prise Salz würzen und die Pfanne vom Herd nehmen.
                </>,
                <>
                    <strong>Flohsamenschalen</strong> mit <strong>100 ml Wasser</strong> vermengen und beseite stellen.
                    Petersilienblätter abzupfen und fein hacken. Die gebratene Pilz-Zwiebel-Mischung,
                    Petersilie, Haferflocken, Paprikapulver und Senf in eine grosse Schüssel geben und mit Flohsamenschalen-Masse verkneten.
                    Falls die Patty-Masse nicht zusammenhält etwas mehr Flohsamenschalen mit Wasser vermengen und dazugeben.
                    Abschliessend mit Salz und Pfeffer abschmecken. Vier große Burger-Patties formen.
                </>,
                <>
                    Bratöl in eine beschichtete Pfanne geben, sodass der Boden gleichmässig mit einer dünnen
                    Schicht Öl bedeckt ist. Einen Teller mit Küchenpapier auslegen.
                    Die Patties bei mittlerer Hitze von beiden Seiten knusprig braten.
                    Aus der Pfanne nehmen und auf das Küchenpapier legen.
                </>,
                <>
                    Zwiebel abziehen und in dünne Ringe schneiden. Tomate waschen und ebenfalls in Scheiben schneiden. Salat waschen und trocknen.
                </>,
                <>
                    <strong>Burgerbrötchen</strong> halbieren und mit den aufgeschnittenen Seiten nach
                    unten kurz in der heissen Pattie-Pfanne anrösten.
                    Zum Abschluss das Brötchen ganz nach Belieben belegen.
                </>
            ]}/>

            <Recipe title="Grillierte Kräuterseitlinge" image="/img/grilliert.webp" ingredients = {[
                {amount: "600 g", ingredient: "Kräuterseitlinge"},
                {amount: "½ Bund", ingredient: "Blattpetersilie"},
                {amount: "1", ingredient: "Thymianzweig"},
                {amount: "2 EL", ingredient: "Weissweinessig"},
                {amount: "1 TL", ingredient: "Honig"},
                {amount: "2 EL", ingredient: "Olivenöl"},
                {amount: "", ingredient: "Salz"},
            ]} preparation={[
                <>
                    Petersilie und Thymian waschen, trocken schütteln und fein hacken und in eine kleine Schüssel geben.
                </>,
                <>
                    Weissweinessig, Honig und Olivenöl zu den Kräutern geben.
                </>,
                <>
                    Knoblauch schälen fein hacken und ebenfalls zur Marinade geben.
                </>,
                <>
                    Marinade gut vermischen und die Kräuterseitlinge für 20-30 Minuten bei Raumtemperatur marinieren.
                </>,
                <>
                    Die Kräuterseitlinge ca. 3 Minuten auf jeder Seite grillieren.
                </>
            ]}/>

            {/*
            <Recipe title="Panierte Kräuterseitlinge" image="/img/paniert.webp" ingredients = {[
                {amount: "500 g", ingredient: "Kräuterseitlinge"},
                {amount: "1 Tasse", ingredient: "Mehl"},
                {amount: "2", ingredient: "Eier"},
                {amount: "2 Tassen", ingredient: "Paniermehl und/oder Panko"},
                {amount: "", ingredient: "Öl zum frittieren"},
                {amount: "", ingredient: "Pfeffer"},
                {amount: "", ingredient: "Salz"},
            ]} preparation={[
                <>
                    Die Pilze in ca. 1 cm dicke Scheiben schneiden.
                </>,
                <>
                    Das Mehl
                </>,
                <>
                    Knoblauch schälen fein hacken und ebenfalls zur Marinade geben.
                </>,
                <>
                    Marinade gut vermischen und die Kräuterseitlinge für 20-30 Minuten bei Raumtemperatur marinieren.
                </>,
                <>
                    Die Kräuterseitlinge ca. 3 Minuten auf jeder Seite grillieren.
                </>
            ]}/>

            */}

        </Card>
      <MKBox pt={3} px={1} mt={3}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Recipes;

import {SvgIcon} from "@mui/material";


export function Mushroom(props) {
    return (
        <SvgIcon viewBox="0 0 375.61 375.61" {...props}>
            <g xmlns="http://www.w3.org/2000/svg">
                <path d="M241.565,331.954c24.673-43.137,39.329-91.604,45.828-140.756c-15.956,0.736-54.73-0.213-54.73-0.213 s0.064-0.109-2.08,6.328c-1.881,5.645-3.891,11.246-6.028,16.799c-2.991,7.896-6.374,15.641-9.924,23.299 c-7.067,15.248-15.519,29.754-25.009,43.613c-8.118,11.318-18.199,20.73-26.615,31.672c-1.859-7.535-6.483-14.461-9.812-21.387 c-5.47-11.387-10.474-22.975-15.089-34.729c-8.478-21.594-15.229-43.939-19.574-66.736c23.652-0.799,41.773-3.104,53.189-5.949 c-9.896-2.618-17.467-6.09-20.368-10.699c-14.005-22.232-10.8-54.449,8.992-90.803c-21.179-27.341-47.385-47.55-60.232-47.55 c-16.543,0-55.244,33.5-76.827,72.598c-9.182,16.632-22.474,47.888-8.331,70.345c3.065,4.866,24.166,9.98,58.898,11.73 c0.762,43.994,9.764,88.119,23.31,129.885c1.148,3.539,2.297,7.078,3.447,10.617c1.307,4.014-4.77,4.953-8.104,6.828 c-8.874,4.994-17.17,11.037-24.54,18.068c-6.359,6.066-12.396,12.947-16.646,20.695c80.343,0,160.687,0,241.029,0 C281.59,356.001,261.458,341.112,241.565,331.954C241.856,331.444,278.694,349.048,241.565,331.954z M51.04,166.548 c-20.529-8.203-24.794-40.795-4.991-53.016c-9.226,7.983-10.867,25.574-4.824,35.7c2.997,4.433,7.125,6.802,12.202,8.06 C60.404,159.02,57.501,169.252,51.04,166.548z"></path>
                <path d="M349.726,85.585C324.283,39.493,278.665,0,259.166,0c-19.506,0-65.132,39.497-90.574,85.594 c-10.821,19.607-26.486,56.454-9.809,82.925c4.447,7.066,41.14,14.575,100.383,14.575c59.242,0,95.936-7.509,100.385-14.574 C376.222,142.045,360.551,105.195,349.726,85.585z M340.124,147.161c-3.054,5.522-8.094,9.04-13.746,11.457 c-6.611,2.828-10.405-6.861-3.647-8.832c19.108-3.807,21.55-32.207,8.017-43.918C343.299,113.615,346.907,134.896,340.124,147.161z "></path>
            </g>
        </SvgIcon>
    );
}
import { useEffect, useRef } from "react";

import Rellax from "rellax";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Navbar from "components/Navbar";
import Footer from "components/Footer";


import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/bg-about.webp";
import Eryngii from "./sections/Eryngii";

function Species() {
    const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);


  return (
    <>
      <Navbar
          light
          brand={footerRoutes.brand}
          routes={routes}
        /*action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "default",
        }}*/
          transparent
      />
      <MKBox
        ref={headerRef}
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            size={{xs: 12, lg: 8}}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
          <MKTypography
                  variant="h2"
                  color="white"
                  mb={2}
                  sx={({ breakpoints, typography: { size } }) => ({
                      [breakpoints.down("md")]: {
                          fontSize: size["3xl"],
                      },
                  })}
              >
              Arten
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
        <Card
            sx={{
                p: 0,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                background: 'linear-gradient(195deg, #11a37c21, #1e7f6421)',
                backdropFilter: "saturate(100%) blur(10px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                overflow: "hidden",
                minHeight: "20vh"
            }}
        >
            <MKBox>
                <Eryngii />
            </MKBox>
        </Card>
      <MKBox pt={3} px={1} mt={3}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Species;
